import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { navigate } from "gatsby"

import { useCustomerContext } from "../../../hooks/useCustomer"
import { useTemplate } from "../../../hooks/useTemplate"
import { useWishlist, useWishlistContext } from "../../../hooks/useWishlist"

export const withAccountWishlist = Component =>
  memo(({ name = "AccountWishlist", location, page, template }: any) => {
    const { customer } = useCustomerContext()
    const { global } = useTemplate()
    const { addToWishlist, existsInWishlist, shareWishlist, loading } = useWishlist(location)
    const { wishlist: items, added: addedItem, setAdded: setAddedItem, setScroll } = useWishlistContext()
    const [added, setAdded] = useState(null)

    const locales = useMemo(
      () => ({
        ...global,
        ...page,
      }),
      [global, page]
    )
    const title = customer?.firstName ? locales?.additionalHeading?.replace(`{name}`, customer?.firstName) : page?.title
    const handleClick = useCallback(() => {
      if (added?.scroll) setScroll(added?.scroll)
      navigate(added?.url)
    }, [added, setScroll])
    const handleShare = useCallback(network => shareWishlist(network, title), [title, shareWishlist])

    // intentionally only run when context is updated
    useEffect(() => {
      if (addedItem) {
        const exists = existsInWishlist(addedItem?.product?.id)

        if (exists) {
          if (!added) {
            setAdded({
              message: page?.additionalAdded?.replace(`{title}`, `“${addedItem?.product?.title}”`),
              scroll: addedItem?.scroll,
              url: addedItem?.url,
            })
            setAddedItem(null)
          }
        } else {
          addToWishlist(addedItem?.product, addedItem?.url, addedItem?.scroll)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addedItem])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          added={added}
          handleClick={handleClick}
          handleShare={handleShare}
          items={items}
          loading={loading}
          locales={locales}
          page={page}
          template={template}
          title={title}
        />
      ),
      [added, handleClick, handleShare, items, loading, locales, page, template, title]
    )
  })
