import React from "react"

import { withAccountWishlist } from "./withAccountWishlist"
import { Icon } from "../../Icon/Icon"
import { AccountHeader } from "../Header/AccountHeader"
import { AccountWishlistList } from "./List/AccountWishlistList"
import { StyledContainer } from "../../Styled/Container"
import { StyledColumn } from "../../Styled/Column"
import { H3 } from "../../Styled/Text"
import { Page, Row, Link, Sharing, Share, StyledNotification, NotificationText } from "./styledAccountWishlist"

export const AccountWishlist = withAccountWishlist(
  ({ added, handleClick, handleShare, items, loading, locales, page, template, title }): JSX.Element => (
    <>
      <AccountHeader location={location} page={page} template={template} />
      <Page>
        <StyledContainer width={`xl`}>
          <Row gutter items={`end`} withSpacing={items?.length > 0 ? `xxl` : `sm`} withWrap>
            <StyledColumn gutter width={`md:1/3`} />
            <StyledColumn gutter width={`md:1/3`}>
              <H3 as={`h1`} align={`center`}>
                {title}
              </H3>
            </StyledColumn>
            <StyledColumn gutter width={`md:1/3`}>
              {items?.length > 0 && (
                <Sharing items={`center`} withWrap>
                  <Share large>{locales?.additionalShare}</Share>
                  <Link colour={`grey-darker`} onClick={() => handleShare(`email`)} title={locales?.additionalEmail}>
                    <Icon name={`email`} height={20} width={20} />
                  </Link>
                  <Link colour={`grey-darker`} onClick={() => handleShare(`facebook`)} title={locales?.additionalFacebook}>
                    <Icon name={`social-facebook`} height={24} width={24} />
                  </Link>
                  <Link colour={`grey-darker`} onClick={() => handleShare(`pinterest`)} title={locales?.additionalPinterest}>
                    <Icon name={`social-pinterest`} height={24} width={24} />
                  </Link>
                </Sharing>
              )}
            </StyledColumn>
          </Row>
          {added && (
            <StyledNotification
              align={`center`}
              colour={`orange-dark`}
              onClick={added?.url ? handleClick : null}
              title={page?.additionalContinue}
              withSpacing={`sm`}
            >
              <NotificationText>{added?.message}</NotificationText>
              <NotificationText>{page?.additionalContinue}</NotificationText>
              <Icon name={`right`} width={12} />
            </StyledNotification>
          )}
          <AccountWishlistList items={items} loading={loading} page={page} />
        </StyledContainer>
      </Page>
    </>
  )
)
