import tw, { styled } from "twin.macro"

import { StyledLink } from "../../Styled/Button"
import { StyledRow } from "../../Styled/Row"
import { Small, Notification } from "../../Styled/Text"

export const Page = tw.section`pt-11 pb-15 md:pt-16 md:pb-24`
export const Link = tw(StyledLink)`block mx-1 md:mr-0 md:ml-2`
export const Row = styled(StyledRow)`
  ${({ withSpacing }) => withSpacing === `xxl` && tw`mb-14`}
`
export const Sharing = tw(StyledRow)`justify-center md:justify-end md:mb-2`
export const Share = tw(Small)`mr-4 font-normal w-full md:flex-shrink-0 md:w-auto text-center md:text-right mt-5 mb-3.5 md:my-0`
export const StyledNotification = tw(
  Notification
)`w-full flex items-center justify-center hover:bg-orange-dark hover:text-white transition-colors duration-200 cursor-pointer`
export const NotificationText = tw.span`block mr-2`
