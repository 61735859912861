import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { AccountWishlist as Page } from "../../components/Account/Wishlist/AccountWishlist"

export const query = graphql`
  query {
    page: sanityPageAccountWishlist {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      more: _rawMore(resolveReferences: { maxDepth: 6 })
      link: _rawLink(resolveReferences: { maxDepth: 6 })
      additionalHeading
      additionalHeadingShared
      additionalSave
      additionalSaveButton
      additionalShare
      additionalAdded
      additionalContinue
      additionalEmpty
      additionalLink
    }
    template: sanityTemplateAccount {
      bannerTitle
      bannerText
      bannerBackground
      bannerImage: _rawBannerImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = withAuthentication(({ data, ...props }): JSX.Element => <Page {...props} {...data} />)

export default Component
