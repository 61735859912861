import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withAccountWishlistList } from "./withAccountWishlistList"
import { ProductCard } from "../../../Product/Card/ProductCard"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"
import { H4, H5 } from "../../../Styled/Text"
import { Button, Row } from "./styledAccountWishlistList"

export const AccountWishlistList = withAccountWishlistList(
  ({ handleSave, items, link, locales, shared }): JSX.Element => (
    <>
      {items?.length > 0 ? (
        <>
          <Row gutter={`sm`} items={`stretch`} justify={`start`} withWrap>
            {items?.map(product => (
              <StyledColumn key={product?.id?.toString()} gutter={`sm`} vertical width={`1/2 md:1/3`}>
                <ProductCard product={product} withSpacing />
              </StyledColumn>
            ))}
          </Row>
          {shared ? (
            <>
              <H4 align={`center`} withSpacing={`sm`}>
                {locales?.additionalSave}
              </H4>
              <StyledRow justify={`center`}>
                <Button colour={`purple`} onClick={handleSave} size={`primary`} title={locales?.additionalSaveButton}>
                  {locales?.additionalSaveButton}
                </Button>
              </StyledRow>
            </>
          ) : (
            link?.url && (
              <>
                <H4 align={`center`} withSpacing={`sm`}>
                  {locales?.additionalLink}
                </H4>
                <StyledRow justify={`center`}>
                  <Button as={GatsbyLink} colour={`purple`} size={`primary`} title={link?.title} to={link?.url}>
                    {link?.title}
                  </Button>
                </StyledRow>
              </>
            )
          )}
        </>
      ) : (
        <>
          <H5 align={`center`} colour={`grey-darker`} large withSpacing={`lg`}>
            {locales?.additionalEmpty}
          </H5>
          {link?.url && (
            <StyledRow justify={`center`}>
              <Button as={GatsbyLink} colour={`purple`} size={`primary`} title={link?.title} to={link?.url}>
                {link?.title}
              </Button>
            </StyledRow>
          )}
        </>
      )}
    </>
  )
)
