import React, { memo, useMemo } from "react"

import { useRoutes } from "../../../../hooks/useRoutes"

export const withAccountWishlistList = Component =>
  memo(({ name = "AccountWishlistList", handleSave = null, items, loading, page, shared = false }: any) => {
    const { linkResolver } = useRoutes()

    const link = linkResolver(items?.length > 0 ? page?.more : page?.link)

    Component.displayName = name
    return useMemo(
      () => (!loading ? <Component handleSave={handleSave} items={items} link={link} locales={page} shared={shared} /> : null),
      [handleSave, items, link, loading, page, shared]
    )
  })
